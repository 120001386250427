
const personalityTraits = [
  {
    title: "Confronting Conflicts",
    tagline: "Willing to speak out and even challenge the status quo.",
    ability: "Confronting Conflicts",
    description:
      "Handle and resolve conflict promptly; confronting underperformance quickly when individuals fail to meet expectations, taking corrective action. Challenge the status quo, even at personal risk, willing to speak out.",
    iAmDescription:
      "Known to handle and resolve conflicts as a Conflict Confronter. An honest and the outspoken person.",
    icon: require("@/assets/images/icons/personality-icons/Conflict Confronter.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Conflict Confronter.png"),
    id: "conflict-confronter",
    personalityMapId: 37,
    background: "rgba(113, 64, 255, 0.1)!important",
    characterType: "strength",
    border: "1px solid #D8D5D5",
    mapId: 1,
  },
  {
    title: "Networking Socially",
    tagline: "Open personality, social and easy to mingle with.",
    ability: "Networking Socially",
    description:
      "Cooperating well with others; delivering great service to customers, with a focus on their needs; actively building and managing social networks; sharing an appropriate amount of personal information so that others can get to know you; working effectively with people in authority.",
    iAmDescription:
      "Open personality, social and easy to mingle with as a Social Networker. I probably place the needs of others over my own needs.",
    icon: require("@/assets/images/icons/personality-icons/Social Networker.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Social Networker.png"),
    id: "social-networker",
    personalityMapId: 38,
    background: "rgba(220, 0, 50, 0.1)!important",
    characterType: "charisma",
    border: "1px solid #D8D5D5",
    mapId: 2,
  },
  {
    title: "Communication Skills",
    tagline: "Sharing wisdom with you with an ability to explain.",
    ability: "Communication Skills",
    description:
      "Clearly explaining a point of view and its reasoning in both one-on-one and large audience situations; writing accurately and professionally in multiple formats, with the correct amount of detail; keeping people informed with relevant and well-timed information.",
    iAmDescription:
      "As a Skilled Communicator I carry an ability to explain. I am comfortable with self-expression, fostering healthy teams.​",
    icon: require("@/assets/images/icons/personality-icons/Skilled Communicator.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Skilled Communicator.png"),
    id: "skilled-communicator",
    personalityMapId: 39,
    background: "rgba(220, 0, 47, 0.1)!important",
    characterType: "charisma",
    border: "1px solid #D8D5D5",
    mapId: 3,
  },
  {
    title: "Compassion and Care",
    tagline:
      "The altruist, moved by compassion, generosity and a desire to help others.",
    ability: "Compassion and Care",
    description:
      "Being friendly and easy to approach; demonstrate genuine concern for an interest in others; listening with an interest in understanding the thoughts and feelings of others.",
    iAmDescription:
      "Considered to be a Considerate Carer, I am moved by compassion with a desire to help others. Extremely perceptive of other’s feelings and get along well with others in team.",
    icon: require("@/assets/images/icons/personality-icons/Compassionate Carer.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Compassionate Carer.png"),
    id: "compassionate-carer",
    personalityMapId: 40,
    background: "rgba(255, 123, 145, 0.1)!important",
    characterType: "compassion",
    border: "1px solid #D8D5D5",
    mapId: 4,
  },
  {
    title: "Trust and Responsibility",
    tagline: "Trustworthy with a high moral and ethics",
    ability: "Trust and Responsibility",
    description:
      "Acting ethically; treating people fairly regardless of race, gender, cultural differences; accepting accountability for personal actions; being considered trustworthy by others.",
    iAmDescription:
      "Trustworthy with a high moral and ethics carrying traits of Responsible Person. I am known to be well-organised and hardworking, and work steadily towards identified goals.",
    icon: require("@/assets/images/icons/personality-icons/Responsible Citizen.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Responsible Citizen.png"),
    id: "responsible-citizen",
    personalityMapId: 41,
    background: "rgba(255, 123, 145, 0.1)!important",
    characterType: "compassion",
    border: "1px solid #D8D5D5",
    mapId: 5,
  },
  {
    title: "Methodical Organization",
    tagline:
      "Interacts with others confidently; is tactful and can communicate in a non-confrontational and polite manner",
    ability: "Methodical Organization",
    description:
      "Being well organized and methodical, paying attention to detail; having a high level of productivity and using time well to get work done; setting definite milestones and actions for achieving goals.",
    iAmDescription:
      "Goals, plans and attention to detail come naturally to me. I am a Methodical Organizer with high standards of performance.",
    icon: require("@/assets/images/icons/personality-icons/Methodical Organiser.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Methodical Organiser.png"),
    id: "methodical-organizer",
    personalityMapId: 42,
    background: "rgba(68, 204, 136, 0.1)!important",
    characterType: "dexterity",
    border: "1px solid #D8D5D5",
    mapId: 6,
  },
  {
    title: "Motivational Influence",
    tagline: "Inspirational and the opposite virtues of being ordinary.",
    ability: "Motivational Influence",
    description:
      "Inspiring others to accept and trust a vision of the future; knowing what motivates different types of people and get the best out of them; developing others and providing an opportunity for them to practice new skills or improve existing ones; ability to pull people together as a team for a common purpose.",
    iAmDescription:
      "Inspirational & Cheerful and the opposite virtues of being ordinary. I am known to inspire others to accept and trust a vision of the future with a well-developed wit.",
    icon: require("@/assets/images/icons/personality-icons/Inspirational Motivator.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Inspirational Motivator.png"),
    id: "inspirational-motivator",
    personalityMapId: 43,
    background: "rgba(196, 20, 50, 0.1)!important",
    characterType: "charisma",
    border: "1px solid #D8D5D5",
    mapId: 7,
  },
  {
    title: "Calming Influence",
    tagline: "Elegant. Patient, humble and calm under pressure",
    ability: "Calming Influence",
    description:
      "Remaining calm under pressure; recognizing the contribution of others and acts humbly about strengths and achievements; being patient with people and knowing when to slow down to let others catch up; balancing work and personal priorities.",
    iAmDescription:
      "Calming Influence in the midst of a highly energetic environment.  I am an elegant, patient, humble and calm individual.",
    icon: require("@/assets/images/icons/personality-icons/Calming Presence.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Calming Presence.png"),
    id: "calming-influence",
    personalityMapId: 44,
    background: "rgba(255, 123, 145, 0.1)!important",
    characterType: "compassion",
    border: "1px solid #D8D5D5",
    mapId: 8,
  },
  {
    title: "Practical Solutions",
    tagline:
      "The go-to person. Continuously improving and solving challenging problems.",
    ability: "Practical Solutions",
    description:
      "Always looking for ways to improve processes; taking new ideas from concept to reality by managing the innovation process; solving challenging problems by using a combination of intuition and analytical thinking; quickly realizing the impact new technology and finding opportunities to integrate it.",
    iAmDescription:
      "I am generally the Practical Fixer, the go-to person. Continuously improving and solving challenging problems.",
    icon: require("@/assets/images/icons/personality-icons/Practical Fixer.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Practical Fixer.png"),
    id: "practical-fixer",
    personalityMapId: 45,
    background: "rgba(68, 204, 136, 0.1)!important",
    characterType: "dexterity",
    border: "1px solid #D8D5D5",
    mapId: 9,
  },
  {
    title: "Learning Continuously",
    tagline: "Good general knowledge with life skills in their arsenal.",
    ability: "Learning Continuously",
    description:
      "Continuously develop new skills and enhance existing capabilities; having a good general knowledge and making an effort to stay up to date with current affairs; have the functional and technical knowledge with skills to get the job done.",
    iAmDescription:
      "As a Constant Learner, I am passionately curious and inquisitive. Good general knowledge with life skills to my advantage.",
    icon: require("@/assets/images/icons/personality-icons/Constant Learner.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Constant Learner.png"),
    id: "constant-learner",
    personalityMapId: 46,
    background: "rgba(103, 216, 243, 0.1)!important",
    characterType: "intelligence",
    border: "1px solid #D8D5D5",
    mapId: 10,
  },
  {
    title: "Flexibility",
    tagline:
      "Self-aware. Adapts behaviour to the interpersonal style and needs of others.",
    ability: "Flexibility",
    description:
      "Adapting behaviour to the interpersonal style and needs of others; is open to feedback and aware of strengths and weaknesses.",
    iAmDescription:
      "I am a Flexible Character. I am able to adapt to the interpersonal style and needs of others. I am aware of myself known to be original and creative.",
    icon: require("@/assets/images/icons/personality-icons/Flexible Character.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Flexible Character.png"),
    id: "flexible-character",
    personalityMapId: 47,
    background: "rgba(68, 204, 136, 0.1)!important",
    characterType: "dexterity",
    border: "1px solid #D8D5D5",
    mapId: 11,
  },
  {
    title: "Thinking Big",
    tagline: "Intuitive, cognitive and caters to a broader perspective.",
    ability: "Thinking Big",
    description:
      "Shifting focus away from the details and gain a broader perspective; envisioning future scenarios based on a combination of intuition, trends and data; dealing with complex intellectual and cognitive concepts.",
    iAmDescription:
      "I am a Big Picture Thinker with an intuitive and cognitive mind that caters to a broader perspective. Exceptionally capable in organising and running activities and projects.",
    icon: require("@/assets/images/icons/personality-icons/Big Picture Thinker.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Big Picture Thinker.png"),
    id: "big-picture-thinker",
    personalityMapId: 48,
    background: "rgba(103, 216, 243, 0.1)!important",
    characterType: "intelligence",
    border: "1px solid #D8D5D5",
    mapId: 12,
  },
  {
    title: "Negotiating Effectively",
    tagline: "Goals, plans and attention to detail.",
    ability: "Negotiating Effectively",
    description:
      "Interacting with others confidently; communicating in a tactful, non-confrontational and polite manner; reaching win-win outcomes even when there are conflicting interests and limited resources; knowing how to manage the politics of relationships.",
    iAmDescription:
      "As an Effective Negotiator, I am confident, tactful and know the respectful of relationships. Known to reach win-win outcomes even when there are conflicting interests and limited resources.",
    icon: require("@/assets/images/icons/personality-icons/Effective Negotiator.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Effective Negotiator.png"),
    id: "effective-negotiator",
    personalityMapId: 49,
    background: "rgba(242, 147, 0, 0.1)!important",
    characterType: "wisdom",
    border: "1px solid #D8D5D5",
    mapId: 13,
  },
  {
    title: "Decision Making",
    tagline: "Experienced, exercises good judgment when making decisions.",
    ability: "Decision Making",
    description:
      "Making decisions, even in the absence of complete data; exercising good judgment when making decisions while taking into account both facts and personal experience; prioritizing between critical and less important tasks; accurately identifying strengths, weaknesses and potential in people with relatively short exposure to them.",
    iAmDescription:
      "Experienced to understand and exercise a good judgment as a Sound Decision Maker. Known to be creative, resourceful, and intellectually quick.",
    icon: require("@/assets/images/icons/personality-icons/Sound Decision Maker.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Sound Decision Maker.png"),
    id: "sound-decision-maker",
    personalityMapId: 50,
    background: "rgba(242, 147, 0, 0.1)!important",
    characterType: "wisdom",
    border: "1px solid #D8D5D5",
    mapId: 14,
  },
  {
    title: "Leadership",
    tagline: "Helping you be you (only better)",
    ability: "Leadership",
    description:
      "Providing clear objectives and instructions to others; assigning meaningful and important tasks and responsibilities to others and giving them authority to act; putting useful and meaningful measures in place to monitor work processes.",
    iAmDescription:
      "As a People Manager, with an encouraging attitude. I am extremely intuitive about people, and concerned for their feelings.",
    icon: require("@/assets/images/icons/personality-icons/People Manager.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/People Manager.png"),
    id: "people-leader",
    personalityMapId: 51,
    background: "rgba(242, 147, 0, 0.1)!important",
    characterType: "wisdom",
    border: "1px solid #D8D5D5",
    mapId: 15,
  },
  {
    title: "Risk Taking",
    tagline:
      "Interacts with others confidently; is tactful and can communicate in a non-confrontational and polite manner",
    ability: "Risk Taking",
    description:
      "Comfortable working in a changing and uncertain environment; enjoy trying new things and is willing to experiment, taking calculated risks.",
    iAmDescription:
      "I am a Willing Risk Taker who accepts uncertainty of life and matters. I enjoy trying new things and am willing to experiment and take calculated risks.​",
    icon: require("@/assets/images/icons/personality-icons/Willing Risk Taker.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Willing Risk Taker.png"),
    id: "willing-risk-taker",
    personalityMapId: 52,
    background: "rgba(113, 64, 255, 0.1)!important",
    characterType: "strength",
    border: "1px solid #D8D5D5",
    mapId: 16,
  },
  {
    title: "Enthusiasm and Drive",
    tagline: "Result oriented. Demonstrates a positive can-do attitude",
    ability: "Enthusiasm and Drive",
    description:
      "Consistently achieving results and meeting goals and targets; being motivated to achieve career advancement; stick to a course of action despite obstacles and seeing things through to the end; taking initiative and demonstrate a positive can-do attitude.",
    iAmDescription:
      "I am a Result oriented person, demonstrating a positive can-do attitude at work with an Energetic Spirit. Enthusiastic, idealistic, and creative.",
    icon: require("@/assets/images/icons/personality-icons/Energetic Driver.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Energetic Driver.png"),
    id: "energetic-driver",
    personalityMapId: 53,
    background: "rgba(113, 64, 255, 0.1)!important",
    characterType: "strength",
    border: "1px solid #D8D5D5",
    mapId: 17,
  },
  {
    title: "Imagination",
    tagline:
      "Creative and innovative with work and life. Ideas are always flickering in the bulb over the head.",
    ability: "Imagination",
    description:
      "Creating new ideas by seeing new connections between different and sometimes unrelated concepts; being open to different and sometimes conflicting ideas or concepts and willing to change accordingly.",
    iAmDescription:
      "An Imaginative Mind with a logical, original and creative thoughtful work and life. Ideas are always flickering in the bulb over my head.​",
    icon: require("@/assets/images/icons/personality-icons/Imaginative Mind.svg"),
    pngIcon: require("@/assets/images/icons/personality-icons/personality-png/Imaginative Mind.png"),
    id: "imaginative-mind",
    personalityMapId: 54,
    background: "rgba(103, 216, 243, 0.1)!important",
    characterType: "intelligence",
    border: "1px solid #D8D5D5",
    mapId: 18,
  },
];



export default personalityTraits;
