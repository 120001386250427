const messagingConfig = {
  messages: {
    error: {
      unknown_error: "Oops! Something went wrong. Please try again later.",
      non_authenticated_error: "You need to be logged in to continue.",
    },
    abilitySelection: {
      chooseMoreAbility: {
        title: "Choose 6 more strengths",
        text: (name) => {
          return `Choose 6 more strengths (Characteristics that MOST describe ${
            name ? name : "you"
          })`;
        },
      },
      completion: {
        title: (name) => {
          return `${name ? name : "Your"} self-assessment is completed !`;
        },
        text: (name) => {
          return `It's ${
            name ? name + "'s" : "your"
          }  best to least known abilities now prioritize the ones you develop and invite a few advisors for a 360° view.`;
        },
      },
      wantToDevelop: {
        title: (name) => {
          return `Choose the ability ${name ? name : "you"} want to develop !`;
        },
        text: "Click on the abilities and rank priority of development as per the required context.",
      },
    },
    endorsementCompletion: {
      text: (name) => {
        return `${name ? name : ""} self-assessment is completed !`;
      },
    },
    resetAbilitiy: {
      text: (name) => {
        return ` ${
          name ? name + "'s" : "Your"
        } ability have been  reset successfully !`;
      },
    },
    resetPriority: {
      text: (name) => {
        return ` ${
          name ? name + "'s" : "Yours"
        } priorities have been reset  successfully !`;
      },
    },
    priorityAlreadyExist: {
      title: "Ability already prioritized !",
      text: (priority) => {
        return `${priority.name} already exist on ${priority.number}.`;
      },
    },
    inviteGuardian: {
      text: (name) => {
        return `${name} is invited successfully !`;
      },
    },
    resendInvitation: {
      text: "Invitation re-sent successfully !",
    },
    mailSent: {
      text: "Magic link sent successfully",
    },
    not_loggedIn: {
      text: "Login to interact with the app.",
    },
    submitFeedback: {
      text: "Feedback submitted successfully",
    },
    user_already_added: {
      text: (email) => {
        return `${email} is already invited !`;
      },
    },
    talent_report_not_generated: {
      text: "Talent report is not yet generated. You are waiting for advisors to give you feedback. You may nudge them for feedback and come back later.",
    },
  },
};

export { messagingConfig };
